<template>
    <el-dialog tabindex="-1" width="800px" top="40px" title="Granska resultaträkning" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false" @closed="resetModalState">
        <PdfCard :client-info="clientInfo" :data="incomeStatementData" :table-data="resultData" :title="title" />
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="default">Stäng</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
        },
        clientInfo: {
            type: Object,
        },
        incomeStatementData: {
            type: Object,
        },
        resultData: {
            type: Array,
        },
    },

    components: {
        PdfCard: () => import("../../components/FinancialPdfCard.vue"),
    },

    data() {
        return {
            formData: {},
            title: "Resultaträkning",
        };
    },

    methods: {
        resetModalState() {
            this.formData = {};
        },

        closeModal() {
            this.$emit("close");
        },
    },
};
</script>
